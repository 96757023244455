<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>个性推荐策略</el-breadcrumb-item>
        <el-breadcrumb-item>{{show?'编辑':'新增'}}选股策略</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always" class="add-container">
      <div>
        <div class="title">选股策略</div>
        <el-form ref="form" :model="strategyForm" :rules="rules" label-position="right" label-width="110px" class="policy-content-form">
          <el-form-item label="策略名称" prop="strategyName">
            <el-input v-model.trim="strategyForm.strategyName" style="width: 270px" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="策略简介" prop="strategyDescription">
            <el-input type="textarea" v-model.trim="strategyForm.strategyDescription" style="width: 270px" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item label="更新方式">
            <el-select @change="changeUpdateMethod" v-model="strategyForm.updateMethod" placeholder="请选择" style="width: 270px">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推送角色" prop="roleList">
            <el-checkbox-group v-model="strategyForm.roleList">
              <el-checkbox v-for="(item,index) in roleList" :key="index" :label="item.roleName" name='type'></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="strategyForm.updateMethod==='1'">
        <div class="title">自动重复设置</div>
        <!--  -->
        <el-form ref="automaticForms" :rules="rules" :model='automaticForm' label-position="left" label-width="140px" class="policy-analysis-form">
          <el-form-item label="数据开始日期" prop='automaticStartDate'>
            <el-date-picker style="width: 270px" :disabled-date="setDisableDate" :picker-options="pickerOptions" v-model="automaticForm.automaticStartDate" value-format="YYYY-MM-DD" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="选择账号" prop='account'>
            <el-select @change='changeAccount' v-model="automaticForm.account" placeholder="请选择" style="width: 270px">
              <el-option v-for="item in accountList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择个性策略" prop='categoryId'>
            <el-select v-model="automaticForm.categoryId" placeholder="请选择个性策略" style="width: 270px">
              <el-option v-for="item in strategyPersonality" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="strategyForm.updateMethod==='2'">
        <div class="title">手动单次设置</div>
        <el-form ref="manualSetting" :rules="rules" :model='manualSettingForm' label-position="left" label-width="140px" class="policy-analysis-form">
          <el-form-item label="数据日期" prop='manualDate'>
            <el-date-picker :disabled-date="setDisableDate" style="width: 270px" value-format="YYYY-MM-DD" v-model="manualSettingForm.manualDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
            <!-- <el-date-picker style="width: 270px" value-format="YYYY-MM-DD" v-model="manualSettingForm.manualDate" type="date" placeholder="选择日期">
            </el-date-picker> -->
          </el-form-item>
          <el-form-item label="上传股票列表">
            <div style="width:300px;display:flex">
              <div style="width:200px;">
                <el-upload class="upload-demo" accept='.xlsx,.xls' :before-upload="beforeUpload" :http-request="upload" action :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                  <el-button size="small" style="color: #0f40f5;background:transparent;border:none;fontSize:14px;fontWeight:bold">{{fileList.length===0?'点击上传':'重新上传'}}</el-button>
                </el-upload>
              </div>
              <div style="">
                <a :href=baseUrl download='stock.xlsx' target="view_window" class="link">下载模板</a>
              </div>
            </div>

          </el-form-item>
          <el-form-item label="股票数量(成功/失败)">
            <div>{{successStockList.length}}/{{failStockList.length}}</div>
          </el-form-item>
          <el-form-item label="股票清单">
            <div class="stockMenu">
              <div class="changeBtn">
                <div :class="{common:status===item}" @click="changeBtn(item)" v-for="(item,index) in changeBtns" :key="index">{{item}}</div>
              </div>
              <div>
                <el-table :height="stockList.length>4?'249':49+40*stockList.length" :data="stockList" border style="width: 100%">
                  <el-table-column type="index" label="序号" width="150">
                  </el-table-column>
                  <el-table-column prop="stockCode" label="股票代码" width="150">
                  </el-table-column>
                  <el-table-column prop="stockName" label="股票名称" width="150">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-form-item>

        </el-form>
      </div>

      <div class="btn-group">
        <div class="btn submit" @click="submit">提交</div>
        <div class="btn cancel" @click="cancel">取消</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, getCurrentInstance, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { formatterDate } from "../../../utils/format";
import axios from "axios";
const http = axios.create({
  baseURL: "/api",
  headers: {
    "api-version": "1.0.0",
    "Content-Type": "multipart/form-data",
    'token': localStorage.getItem('user_token'),
  }
});
http.interceptors.request.use(res => {
  res.headers.token = localStorage.getItem('user_token');
  return res;
});
const baseUrl = location.protocol + "//" + location.host + '/static/stock.xlsx'
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const form = ref();
    const automaticForms = ref();
    const manualSetting = ref();
    const state = reactive({
      show: false,
      // fileUrl: 'http://10.168.254.127:8081/static/stock.xlsx',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      strategyForm: {
        strategyDescription: "",//策略简介
        strategyName: "",//策略名称
        updateMethod: "",//更新方式
        roleList: [],
      },
      automaticForm: {
        automaticStartDate: "",//开始日期
        account: "", //账号
        categoryId: "",//收藏夹id
      },
      manualSettingForm: {
        manualDate: ''
      },
      type: "",
      options: [],
      accountList: [],
      strategyPersonality: [],
      degreeOptions: [],
      id: "",
      rules: {
        strategyName: [
          {
            required: true,
            message: "请输入策略名称",
            trigger: "blur",
          },
        ],
        automaticStartDate: [
          {
            type: "date",
            required: true,
            message: "请选择数据开始日期",
            trigger: "change",
          },
        ],
        manualDate: [
          {
            // type: "daterange",
            required: true,
            message: "请选择数据日期",
            trigger: "change",
          },
        ],
        strategyDescription: [
          { required: true, message: "请输入策略简介", trigger: "blur" },
        ],
        account: [
          { required: true, message: "请选择账号", trigger: "change" },
        ],
        categoryId: [
          { required: true, message: "请选择个性策略", trigger: "change" },
        ],
        roleList: [
          { type: 'array', required: true, message: '请至少选择一个角色', trigger: 'change' }
        ],
      },
      fileList: [],
      roleList: [],
      stockList: [],
      successStockList: [],
      failStockList: [],
      changeBtns: ['处理成功', '处理失败'],
      status: '处理成功'

    });
    state.type = route.query.type
    watch(
      () => state.strategyForm.updateMethod,
      (val) => {
        if (val === '1') {
          state.fileList = []
          state.tableData = []
        }
      }
    );
    onMounted(() => {
      getRoleList()
      updateMethodEnum()
      if (route.query.id) {
        state.show = true
        getStrategyDetail()
      } else {
        getAccountEnum()
      }
    });
    const setDisableDate = (v) => {
      return v.getTime() <= new Date().getTime() - 24 * 60 * 60 * 1000
    }
    const getStrategyDetail = () => {
      let params = `?strategyId=${route.query.id}`
      proxy.$get("/cts/back/strategy/detail" + params).then((res) => {
        if (res.resultState === "200") {
          const { account, automaticStartDate, categoryId, customerId, manualDate,
            roleList, stockList, strategyDescription, strategyName, updateMethod } = res.data
          state.strategyForm.strategyDescription = strategyDescription
          state.strategyForm.strategyName = strategyName
          state.strategyForm.updateMethod = updateMethod
          roleList.forEach(item => {
            state.strategyForm.roleList.push(item.roleName)
          })
          state.automaticForm.automaticStartDate = automaticStartDate
          proxy.$get("/cts/back/customer/accountEnum").then((res) => {
            if (res.resultState === "200") {
              state.accountList = res.data
              state.automaticForm.account = customerId
              getUserCategory(customerId)
              state.automaticForm.categoryId = categoryId
            }
          })
          if (manualDate) {
            let arr = []
            arr[0] = automaticStartDate
            arr[1] = manualDate
            state.manualSettingForm.manualDate = arr
          }
          state.stockList = stockList ? stockList : []
          state.successStockList = stockList ? stockList : []
        }
      })
        .catch((err) => { });
    };
    // 更新方式
    const updateMethodEnum = () => {
      proxy.$get("/cts/back/strategy/updateMethodEnum").then((res) => {
        if (res.resultState === "200") {
          state.options = res.data;
          state.strategyForm.updateMethod = res.data[0].value
        }
      })
        .catch((err) => { });
    };
    // 角色列表
    const getRoleList = () => {
      proxy.$get("/cts/back/role/allRoleList").then((res) => {
        if (res.code === "200") {
          state.roleList = res.data;
        }
      })
        .catch((err) => { });
    };
    // 账户列表
    const getAccountEnum = () => {
      proxy.$get("/cts/back/customer/accountEnum").then((res) => {
        if (res.resultState === "200") {
          state.automaticForm.account = res.data[0].value
          state.accountList = res.data
          getUserCategory(state.automaticForm.account)
        }
      })
        .catch((err) => { });
    };
    // 个性策略列表
    const getUserCategory = (item) => {
      let params = `?customerId=${item}`
      proxy.$get("/cts/back/strategy/getUserCategory" + params).then((res) => {
        if (res.resultState === "200") {
          state.strategyPersonality = res.data
        }
      })
        .catch((err) => { });
    };
    // 上传
    const upload = (param) => {
      const formData = new FormData();
      formData.append("file", param.file)
      handleUpload(formData)
    }
    const beforeUpload = (file, fileList) => {
      const isExcel =
        file.name.split(".")[1] === "xlsx" ||
        file.name.split(".")[1] === "xls"
      if (!isExcel) {
        ElMessage({
          message: "只能上传xlsx、xls文件",
          type: "error",
        });
      }
      return isExcel;
    };
    const handleRemove = (file, fileList) => {
    };
    const handlePreview = (file) => {
    };
    const handleExceed = (files, fileList) => {
      state.fileList = files
      const formData = new FormData();
      formData.append("file", files[0])
      handleUpload(formData, files)
    };
    const handleUpload = (formData, files) => {
      http.post("/cts/back/strategy/uploadStock", formData).then((res) => {
        if (res.data.resultState === "200") {
          state.stockList = res.data.data.successDo
          state.successStockList = res.data.data.successDo
          state.failStockList = res.data.data.unsuccessfulDo
        } else {
          ElMessage({
            message: res.data.msg,
            type: "error",
          });
          state.fileList = []
          state.stockList = []
          state.successStockList = []
          state.failStockList = []
        }
      })
        .catch((err) => { });
    }
    const beforeRemove = (file, fileList) => {
      state.stockList = []
      state.successStockList = []
      state.failStockList = []
    }
    const changeBtn = (item) => {
      state.status = item
      if (item === '处理成功') {
        state.stockList = state.successStockList
      } else {
        state.stockList = state.failStockList
      }
    }
    const submit = () => {
      form.value.validate((valid) => {
      });
      if (state.strategyForm.updateMethod === '1') {
        automaticForms.value.validate((valid) => {
        });
      }
      if (state.strategyForm.updateMethod === '2') {
        manualSetting.value.validate((valid) => {
        });
      }
      if (!state.strategyForm.strategyName || !state.strategyForm.strategyDescription || state.strategyForm.roleList.length === 0) return
      if (state.strategyForm.updateMethod === '1') {
        if (!state.automaticForm.automaticStartDate || !state.automaticForm.categoryId) return
      }
      if (state.strategyForm.updateMethod === '2') {
        if (!state.manualSettingForm.manualDate) {
          ElMessage({
            message: "请选择日期",
            type: "error",
          });
          return
        }
        if (state.successStockList.length === 0) {
          ElMessage({
            message: "请上传处理成功的股票清单",
            type: "error",
          });
          return
        }

        if (!state.manualSettingForm.manualDate || state.successStockList.length === 0) return
      }
      add()
    }
    const add = () => {
      let roleList = []
      let newRoleList = []
      state.strategyForm.roleList.forEach(item => {
        let role = state.roleList.filter(value => {
          return value.roleName === item
        })
        newRoleList.push(...role)
      })
      newRoleList.forEach(item => {
        let newRole = {}
        newRole['roleId'] = item.roleId
        newRole['roleName'] = item.roleName
        roleList.push(newRole)
      })
      let strategyList = state.strategyPersonality.filter(item => {
        return state.automaticForm.categoryId === item.value
      })
      let accountList = state.accountList.filter(item => {
        return state.automaticForm.account === item.value
      })
      let flag = state.strategyForm.updateMethod === '1'
      let params = {
        account: flag ? accountList[0].label : '',
        automaticStartDate: flag ? state.automaticForm.automaticStartDate : state.manualSettingForm.manualDate[0],
        categoryId: flag ? strategyList[0].value : '',
        categoryName: flag ? strategyList[0].label : '',
        customerId: flag ? accountList[0].value : '',
        manualDate: flag ? '' : state.manualSettingForm.manualDate[1],
        roleList: roleList,
        stockList: flag ? [] : state.successStockList,
        strategyDescription: state.strategyForm.strategyDescription,
        strategyName: state.strategyForm.strategyName,
        updateMethod: state.strategyForm.updateMethod
      }
      if (route.query.id) {
        params['id'] = route.query.id
        proxy.$post("/cts/back/strategy/modify", params).then((res) => {
          if (res.resultState === "200") {
            ElMessage({
              message: res.msg,
              type: "success",
            });
            router.push({ path: '/content/selectedAreas/personalityRecommendationStrategy' })
          }
        })
          .catch((err) => { });
      } else {
        proxy.$post("/cts/back/strategy/add", params).then((res) => {
          if (res.resultState === "200") {
            ElMessage({
              message: res.msg,
              type: "success",
            });
            router.push({ path: '/content/selectedAreas/personalityRecommendationStrategy' })
          }
        })
          .catch((err) => { });
      }

    }
    const cancel = () => {
      router.push({ path: '/content/selectedAreas/personalityRecommendationStrategy' })
      changeUpdateMethod()
    }
    const changeAccount = (val) => {
      state.automaticForm.account = val
      state.automaticForm.categoryId = ''
      getUserCategory(val)
    }
    const changeUpdateMethod = (val) => {
      if (state.manualSettingForm.manualDate && state.type === "add") {
        state.manualSettingForm.manualDate = ''
      }
      manualSetting.value.resetFields();
      automaticForms.value.resetFields();
    }
    return {
      ...toRefs(state),
      submit,
      cancel,
      form,
      automaticForms,
      manualSetting,
      baseUrl,
      beforeUpload,
      handleRemove,
      handlePreview,
      handleExceed,
      beforeRemove,
      upload,
      getRoleList,
      getAccountEnum,
      getUserCategory,
      updateMethodEnum,
      changeAccount,
      changeUpdateMethod,
      handleUpload,
      setDisableDate,
      changeBtn
    };
  },
};
</script>

<style lang="scss" scoped>
.add-container {
  a.link,
  a:visited,
  a:link {
    color: #0f40f5;
    text-decoration: none;
    margin-left: -80px;
  }
  a:hover {
    color: #0f40f5;
  }
  .title {
    font-weight: bold;
  }
  .policy-content-form {
    padding: 20px;
    width: 450px;
  }
  .policy-analysis-form {
    padding: 20px;
    width: 490px;
    .choose-container {
      width: 370px;
      max-height: 260px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 0 10px 0;
      .input {
        display: flex;
        .el-button {
          margin-left: 5px;
        }
      }
    }
    .stockMenu {
      .changeBtn {
        width: 200px;
        display: flex;
        border: 1px solid #ccc;
        text-align: center;
        margin-bottom: 20px;
        div {
          width: 100px;
          box-sizing: border-box;
          cursor: pointer;
          &.common {
            background-color: #93d2f3;
            color: #fff;
          }
        }
        div:first-child {
          border-right: 1px solid #ccc;
        }
      }
    }
  }
  v::deep .el-form-item__label {
    margin-right: 60px;
  }
  .btn-group {
    margin-left: 35px;
    display: flex;
    .btn {
      width: 80px;
      border: 1px solid #dcdcdc;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      &.submit {
        background: #38a28a;
        color: #fff;
        margin-right: 15px;
      }
    }
  }
}
</style>
